import {useSBlocksStore} from "./sBlocksStore";

export const useSBlocksData = () => {
  const {
    gridData,
    setGridData,
    numRows,
    setNumRows,
    numCols,
    setNumCols,
    cellSize,
    setCellSize,
    border,
    setBorder,
    currentLayer,
    setCurrentLayer,
    currentColour,
    setCurrentColour,
    currentShape,
    setCurrentShape,
    currentRotation,
    setCurrentRotation,
    showColourPicker,
    setShowColourPicker,
    showGrid,
    setShowGrid,
    showPaletteTemplates,
    setShowPaletteTemplates,
    colourPalette,
    setColourPalette,
    sBlocks,
    setSBlocks,
    paletteArray,
    setPaletteArray,
    rotationArray,
    layers,
    setLayers,
    showSnackBar,
    setShowSnackBar,
  } = useSBlocksStore((state) => ({
    gridData: state.gridData,
    setGridData: state.setGridData,
    numRows: state.numRows,
    setNumRows: state.setNumRows,
    numCols: state.numCols,
    setNumCols: state.setNumCols,
    cellSize: state.cellSize,
    setCellSize: state.setCellsize,
    border: state.border,
    setBorder: state.setBorder,
    currentLayer: state.currentLayer,
    setCurrentLayer: state.setCurrentLayer,
    currentColour: state.currentColour,
    setCurrentColour: state.setCurrentColour,
    currentShape: state.currentShape,
    setCurrentShape: state.setCurrentShape,
    currentRotation: state.currentRotation,
    setCurrentRotation: state.setCurrentRotation,
    showColourPicker: state.showColourPicker,
    setShowColourPicker: state.setShowColourPicker,
    showGrid: state.showGrid,
    setShowGrid: state.setShowGrid,
    showPaletteTemplates: state.showPaletteTemplates,
    setShowPaletteTemplates: state.setShowPaletteTemplates,
    colourPalette: state.colourPalette,
    setColourPalette: state.setColourPalette,
    sBlocks: state.sBlocks,
    setSBlocks: state.setSBlocks,
    paletteArray: state.paletteArray,
    setPaletteArray: state.setPaletteArray,
    rotationArray: state.rotationArray,
    layers: state.layers,
    setLayers: state.setLayers,
    showSnackBar: state.showSnackBar,
    setShowSnackBar: state.setShowSnackBar,
  }));

  return {
    gridData,
    setGridData,
    numRows,
    setNumRows,
    numCols,
    setNumCols,
    cellSize,
    setCellSize,
    border,
    setBorder,
    currentLayer,
    setCurrentLayer,
    currentColour,
    setCurrentColour,
    currentShape,
    setCurrentShape,
    currentRotation,
    setCurrentRotation,
    showColourPicker,
    setShowColourPicker,
    showGrid,
    setShowGrid,
    showPaletteTemplates,
    setShowPaletteTemplates,
    colourPalette,
    setColourPalette,
    sBlocks,
    setSBlocks,
    paletteArray,
    setPaletteArray,
    rotationArray,
    layers,
    setLayers,
    showSnackBar,
    setShowSnackBar,
  };
};
